body {
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
    overflow-y: auto;
}

#root {
    height: 100vh;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.public-page {
    width: 100vw;
    min-height: 100vh;
}

p {
    margin: 0px;
}
