.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sub-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 1rem;
}
