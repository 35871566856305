.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.table {
    :global(.ant-table-thead) {
        display: none;
    }
}

.img-btn {
    margin-top: 16px;
}

.cancel-btn {
    margin-left: 8px;
}
