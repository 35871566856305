.income {
    color: #3f8600
}

.expense {
    color: #cf1322;
}

.wrapper {
    svg {
        margin-right: 8px !important;
    }
    font-size: 16px !important;
}