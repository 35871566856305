.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.table-order {
    :global(.ant-table-sticky-scroll-bar) {
        background-color: black !important;
    }
}
.header-bold {
    :global(.ant-table-thead>tr>th) {
        background-color: aquamarine !important;
    }
}