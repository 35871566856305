.page-login {
    width: 100%;
    height: 100vh;
    position: relative;

    .login-card {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }

    .title {
        font-size: 24px;
        font-weight: 700;
        text-align: center;
        margin-bottom: 24px;
    }

    .login-form-button {
        margin-bottom: 20px;
        width: 100%;
    }
}

.logo {
    display: flex;
    justify-content: center;
}
