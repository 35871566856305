.remove-btn {
    position: relative;
    top: 4px;
    margin: 0 8px;
    color: #999;
    font-size: 20px;
    cursor: pointer;
    transition: all 0.3s;
}

.product-stock {
    border: dashed 1px;
    border-color: #d9d9d9;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
    border-radius: 0.5rem;
    padding: 1rem;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    background: rgba(0, 0, 0, 0.02);
    margin-bottom: 1rem;

    &:hover {
        border-color: #1677ff;
    }
}

.product-variant {
    padding-left: 2rem;
}
