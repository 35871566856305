.sider {
    min-width: 250px !important;
    overflow: auto;
    height: 100vh;
    position: fixed;
    // background-color: white !important;
}

.logo {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
}
