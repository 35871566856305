.wrapper {
   display: flex;
   justify-content: center;
   flex-direction: column;
   align-items: center;
}

.delete-ic {
    color: #ff4d4f ;
    font-size: 40px;
}